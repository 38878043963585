/** 验证码登录 */
import { Button, Form, Input, Message, Select, Image } from '@nbit/arco'
import {
  LoginValidateRules,
  formatPhoneNumber,
  SafetyVerificationRules,
  PersonalCenterEmailRules,
} from '@/features/user/utils/validate'
import { useEffect, useRef, useState } from 'react'
import UserCountDown from '@/features/user/components/count-down'
import { t } from '@lingui/macro'
import { getMemberLoginCode, postMemberSafeVerifyEmailSend, postMemberSafeVerifyPhoneSend } from '@/apis/user'
import {
  LoginTypeEnum,
  UserSendValidateCodeBusinessTypeEnum,
  UserValidateMethodEnum,
  UserVerifyTypeEnum,
} from '@/constants/user'
import Icon from '@/components/icon'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { useUserStore } from '@/store/user'
import { useGeeTestBind } from '@/features/user/common/geetest'
import { TabsResetPasswordStatusType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { toKenTtlDefaultValue } from '@/constants/auth'

type LoginVerificationCodeType = {
  /** 类型 */
  type?: string
  /** 忘记密码方法 */
  onRetrieve: () => void
  /** 登录方法 */
  handleLoginSuccess: (val: { email: string; mobileCountryCode: string | undefined; mobile: string }) => void
  /** 国家选择弹窗方法 */
  setVisible: (val: boolean) => void
  /** 账号信息筛选方法 */
  getAccountType: (val: string) => { string }
}

const FormItem = Form.Item
function LoginVerificationCode(props) {
  const { type, area, setVisible, onRetrieve, handleLoginSuccess, getAccountType } = props
  const geeTestInit = useGeeTestBind()
  const store = useUserStore()
  const [form] = Form.useForm()
  const rule = LoginValidateRules()
  const { tokenTtl } = store.personalCenterSettings
  const [loading, setLoading] = useState<boolean>(false) // 加载值
  const [methodData, setMethodData] = useState({
    type: UserValidateMethodEnum.email,
  }) // 类型选中值
  const isEmailSend = useRef<boolean>(false) // 邮箱验证码控制值
  const isPhoneSend = useRef<boolean>(false) // 手机验证码控制值
  const rules = SafetyVerificationRules(isEmailSend, isPhoneSend)
  const ruleEmail = PersonalCenterEmailRules(isEmailSend)
  const onSubmit = async value => {
    setLoading(true)
    geeTestInit(
      async () => {
        const res = await getMemberLoginCode({
          email: value?.email,
          mobileCountryCode: area?.codeVal || undefined,
          mobile: value?.mobile,
          loginType:
            methodData.type === UserValidateMethodEnum.email ? UserVerifyTypeEnum.phone : UserVerifyTypeEnum.email,
          code: value?.verifyCode || undefined,
          tokenTtl: tokenTtl || toKenTtlDefaultValue,
        })
        setLoading(false)
        if (res?.isOk && res?.data) {
          const valData = {
            email: res?.data?.userInfo?.uid,
            mobile: value?.mobile,
            mobileCountryCode: area?.codeVal || undefined,
            typeUid: UserVerifyTypeEnum.uid,
          }
          if (res?.data?.pwdReset) {
            store.setUserTransitionDatas({ ...res?.data, ...res?.data?.userInfo, ...res?.data?.merchantSettings })
            const typeVal = getAccountType({ ...valData, email: value?.email })
            store.setIsResetPassword(true)
            store.setResetPasswordStatus({
              isCode: TabsResetPasswordStatusType.passWord,
              type: typeVal,
            })
            return
          }
          handleLoginSuccess({ ...valData, mobile: res?.data?.userInfo?.uid })
        }
      },
      () => {
        setLoading(false)
      }
    )
  }

  /** 发送验证码 */
  const handleSendEmailValidateCode = async () => {
    if (type === UserValidateMethodEnum.email && !form.getFieldValue('email')) {
      Message.error(t`user.validate_form_02`)
      return false
    }
    if (type === UserValidateMethodEnum.phone && !form.getFieldValue('mobile')) {
      Message.error(t`features_user_personal_center_account_security_phone_index_2433`)
      return false
    }

    const res =
      methodData.type !== UserValidateMethodEnum.email
        ? await postMemberSafeVerifyPhoneSend({
            type: UserSendValidateCodeBusinessTypeEnum.login,
            mobileCountryCode: area?.codeVal as string,
            mobile: form.getFieldValue('mobile') as string,
          })
        : await postMemberSafeVerifyEmailSend({
            type: UserSendValidateCodeBusinessTypeEnum.login,
            email: form.getFieldValue('email'),
          })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      methodData.type === UserValidateMethodEnum.email ? (isEmailSend.current = true) : (isPhoneSend.current = true)
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  useEffect(() => {
    form.setFieldValue('email', '')
    form.setFieldValue('mobile', '')
    setMethodData({
      type,
    })
  }, [type])

  useEffect(() => {
    if (store?.selectTabData?.type === LoginTypeEnum.verification) {
      form.setFieldValue('verifyCode', '')
    }
  }, [store?.selectTabData?.type])
  return (
    <div>
      <Form form={form} layout="vertical" onSubmit={onSubmit} autoComplete="off">
        {methodData.type === UserValidateMethodEnum.email ? (
          <FormItem label={t`user.safety_items_04`} field="email" rules={[ruleEmail.email]} requiredSymbol={false}>
            <Input placeholder={t`user.validate_form_02`} />
          </FormItem>
        ) : (
          <FormItem
            label={t`user.validate_form_03`}
            field="mobile"
            rules={[rule.phone]}
            requiredSymbol={false}
            className="custom-arco-form-item"
            formatter={value => formatPhoneNumber(value, area?.codeVal)}
            normalize={value => formatPhoneNumber(value, area?.codeVal, true)}
          >
            <Input
              placeholder={t`user.field.reuse_11`}
              addBefore={
                <Select
                  onClick={() => setVisible(true)}
                  style={{ width: 100 }}
                  popupVisible={false}
                  arrowIcon={<Icon name="arrow_open" />}
                  prefix={
                    <>
                      <Image preview={false} src={`${oss_area_code_image_domain_address}${area?.remark}.png`} /> +
                      {area?.codeVal}{' '}
                    </>
                  }
                />
              }
            />
          </FormItem>
        )}

        <FormItem
          label={methodData.type === UserValidateMethodEnum.email ? t`user.field.reuse_03` : t`user.field.reuse_04`}
          field="verifyCode"
          requiredSymbol={false}
          rules={[methodData.type === UserValidateMethodEnum.email ? rules.emailCode : rules?.phoneCode]}
        >
          <Input
            type="number"
            maxLength={6}
            placeholder={t`features_user_log_register_modal_component_verification_index_pvlmpiqrvh`}
            suffix={<UserCountDown onSend={handleSendEmailValidateCode} />}
          />
        </FormItem>

        <div className="retrieve">
          <label onClick={onRetrieve}>{t`user.login_03`}</label>
        </div>

        <FormItem style={{ marginBottom: 0 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            {t`user.field.reuse_07`}
          </Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default LoginVerificationCode
