/** 登录弹窗左边组件 */
import ShouldGuidePageComponentDisplay from '@/features/home/common/component-should-display'
import Logo from '@/features/layout/components/logo'
import LazyImage from '@/components/lazy-image'
import { getOssManyBusinessImageAddress } from '@/constants/oss'
import { getGuidePageComponentInfoByKey } from '@/helper/layout'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { useLayoutStore } from '@/store/layout'
import { useCommonStore } from '@/store/common'
import { showLocaleInfoContent } from '@/constants/common'
import style from './index.module.css'

interface LoginLeftType {
  /** 是否显示 log */
  isLog?: boolean
  /** 是否显示主题图片 */
  isImage?: boolean
  /** 背景图参数 */
  imageVal?: string
}

function GameLoginLeft2z(props: LoginLeftType) {
  const { localeInfo } = useCommonStore()
  const { isLog = true, isImage = true, imageVal } = props
  const guidePage = useGuidePageInfo()
  const { pageInfoTopBar = [] } = guidePage
  const homeIcon = getGuidePageComponentInfoByKey('homeIcon', pageInfoTopBar)
  const { headerData } = useLayoutStore()
  return (
    <div className={style['log-register-left']}>
      <div className="home-wrap">
        <ShouldGuidePageComponentDisplay {...homeIcon}>
          {isLog && showLocaleInfoContent(localeInfo?.showLoginLogo) && <Logo data={headerData} keepWhite />}
        </ShouldGuidePageComponentDisplay>
      </div>
      <div className="left-back-image">{isImage && <LazyImage className="dg-image" src={imageVal || ''} />}</div>
    </div>
  )
}

export default GameLoginLeft2z
