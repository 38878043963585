import { useState, useEffect, useRef } from 'react'
import { useMount } from 'react-use'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { setToken } from '@/helper/auth'
import { useUserStore } from '@/store/user'
import { QRCodeCanvas } from 'qrcode.react'
import { useLayoutStore } from '@/store/layout'
import { useCountDown, useInterval } from 'ahooks'
import { MemberMemberAreaType } from '@/typings/user'
import { toKenTtlDefaultValue } from '@/constants/auth'
import UserPopUp from '@/features/user/components/popup'
import { usePageContext } from '@/hooks/use-page-context'
import { useGeeTestBind } from '@/features/user/common/geetest'
import SignInWith from '@/features/user/login/component/sign-in-with'
import UserSearchArea from '@/features/user/common/search-area'
import { Button, Form, Input, Select, Image, Message } from '@nbit/arco'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { LoginValidateRules, formatPhoneNumber, loginNameExp } from '@/features/user/utils/validate'
import { IsAccountType, FormValuesTrim, getBrowser, getOperationSystem } from '@/features/user/utils/common'
import {
  postMemberLoginEmail,
  postMemberLoginByUid,
  postMemberLoginPhone,
  getMemberAreaIp,
  postMemberLoginScan,
  postMemberLoginQrcode,
  postMemberLoginGenerateUserInfomation,
  getMemberLoginLoginName,
} from '@/apis/user'
import {
  UserValidateMethodEnum,
  UserVerifyTypeEnum,
  ChinaAreaCodeEnum,
  logRegisterTypeEnum,
  UserUpsAndDownsColorEnum,
  UserEnabledStateTypeEnum,
  GetUserLimitSwitchEnum,
  LoginTypeEnum,
} from '@/constants/user'
import Icon from '@/components/icon'
import { TabsResetPasswordStatusType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { useLimitSwitch } from '@/hooks/features/limitSwitch'
import { mobileVerify } from '@/helper/env'
import LoginSwitch from './login-switch'
import LoginVerificationCode from './login-verification-code'
import styles from './index.module.css'

const FormItem = Form.Item

const jsonData = 'scan-code-bubble'

interface qrCodeLoginInfoType {
  /** 登录信息 */
  loginInfo: string
  /** 验证扫码登录 */
  qrCode: string
}

function UserLogin() {
  const [area, setArea] = useState<MemberMemberAreaType>({
    codeVal: ChinaAreaCodeEnum.code,
    codeKey: t`features_user_personal_center_account_security_phone_index_2432`,
    remark: ChinaAreaCodeEnum.remark,
  })

  const userLoginTypeData = [
    {
      isHide: mobileVerify,
      key: '1',
      name: t`user.safety_items_04`,
      type: logRegisterTypeEnum.email,
      icon: 'icon_mail',
    },
    {
      isHide: mobileVerify,
      key: '2',
      name: t`user.safety_items_02`,
      type: logRegisterTypeEnum.phone,
      icon: 'icon_phone',
    },
    {
      isHide: true,
      key: '3',
      name: t`features/user/personal-center/settings/payment/index-0`,
      type: logRegisterTypeEnum.qrcode,
      icon: 'icon_qr',
    },
  ]
  const [visible, setVisible] = useState<boolean>(false)
  const [qrcodeShow, setQrCodeShow] = useState<boolean>(true)
  const [targetDate, setTargetDate] = useState<number>()
  const [scanTime, setScanTime] = useState<number | undefined>(undefined)
  const [qrcodeButtonDisabled, setQrcodeButtonDisabled] = useState<boolean>(false)
  const [qrCodeLoginText, setQrCodeLoginText] = useState<string>(t`user.login_08`)
  const [qrcodeInfo, setQrCodeInfo] = useState<qrCodeLoginInfoType>()
  const [method, setMethod] = useState<string>(UserValidateMethodEnum.email)
  const [passwordShow, setPasswordShow] = useState<boolean>(false)
  const [resePasswordPopUpTips, setResePasswordPopUpTips] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const qrCodeLoginInfo = useRef<qrCodeLoginInfoType>()
  const popupLimit = useLimitSwitch()

  const { headerData } = useLayoutStore()

  const store = useUserStore()
  const pageContext = usePageContext()
  const geeTestInit = useGeeTestBind()

  const [form] = Form.useForm()
  const { redirect, recreation } = pageContext.urlParsed.search
  const { searchOriginal } = pageContext.urlParsed

  const { tokenTtl } = store.personalCenterSettings

  const rule = LoginValidateRules(
    `${t`features_user_login_index_2604`}/${t`features_agent_invitation_v2_rebate_records_v2_table_schema_6sqojf9pxw`}`
  )

  const getAreaIp = async () => {
    const res = await getMemberAreaIp({})
    if (res.isOk) {
      const { enCode, fullName, shortName } = res.data
      setArea({
        codeVal: enCode || ChinaAreaCodeEnum.code,
        codeKey: fullName || t`features_user_personal_center_account_security_phone_index_2432`,
        remark: shortName || ChinaAreaCodeEnum.remark,
      })
    }
  }

  useMount(getAreaIp)

  const handleChoosMethod = (type: string) => {
    setMethod(type)
    // setDisabled(true)
    form.clearFields()
  }

  const handleSelectArea = (v: MemberMemberAreaType) => {
    setArea(v)
    setVisible(false)
  }

  const handleLoginSuccess = async (res, values) => {
    if (res.isOk && res.data.isSuccess) {
      const cacheData = {
        ...values,
        ...res.data,
      }
      await store.setUserTransitionDatas(cacheData)
      await store.setMerchantSettings({ ...res.data?.merchantSettings })
      if (res.data.pwdReset) {
        const type = getAccountType(values)
        store.setIsResetPassword(true)
        store.setResetPasswordStatus({
          isCode: TabsResetPasswordStatusType.passWord,
          type,
        })
        return
      }
      if (
        (res.data?.merchantSettings.isEmailEnable &&
          res.data?.userInfo.isOpenEmailVerify === UserEnabledStateTypeEnum.enable) ||
        (res.data?.merchantSettings.isGoogleEnable &&
          res.data?.userInfo.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable) ||
        (res.data?.merchantSettings.isMobileEnable &&
          res.data?.userInfo.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable) ||
        (res.data?.merchantSettings.isWithdrawPwdEnable &&
          res.data?.userInfo.isOpenWithdrawalPwd === UserEnabledStateTypeEnum.enable)
      ) {
        redirect && store.setLogModalRedirectUrl(searchOriginal || `?redirect=${redirect}`)
        store.setRegisterStatus({
          isCode: true,
          type: UserValidateMethodEnum.email,
        })
        return
      }
      getMemberLoginGenerate({
        ...values,
        email: loginNameExp.test(values?.email) ? res?.data?.userInfo?.uid : values?.email,
      })
    }
  }

  const getMemberLoginGenerate = async values => {
    const options = {
      account: method === UserValidateMethodEnum.email ? values?.email : values?.mobile,
      accountType: values?.typeUid ? values?.typeUid : getAccountType(values),
      mobileCountryCode: values?.mobileCountryCode,
    }
    const res = await postMemberLoginGenerateUserInfomation(options)
    if (res?.isOk) {
      gologinSuccess(res?.data)
    }
  }

  const getAccountType = values => {
    if (method === UserValidateMethodEnum.email) {
      const isAccountType = IsAccountType(values.email)
      if (isAccountType === UserVerifyTypeEnum.email) {
        return UserVerifyTypeEnum.email
      }
      return UserVerifyTypeEnum.uid
    }
    return UserVerifyTypeEnum.phone
  }

  const gologinSuccess = async data => {
    if (data.token) {
      setToken(data)
      store.setMultipleLoginTime(data?.eventTime)
      await store.setUserInfo({ ...data?.userInfo, ...data?.usrMemberSettingsVO })
      await store.setMemberBaseColor(
        data?.usrMemberSettingsVO?.marketSetting || UserUpsAndDownsColorEnum.greenUpRedDown
      )
      store.setLogin(true)
      await store.removeUserTransitionDatas()
      Message.success(t`features/user/safety-verification/index-0`)
      store?.setIsShowLoginName(store?.isShowLoginName ? store?.isShowLoginName : Boolean(data?.userInfo?.loginName))
      if (recreation) {
        store.setLogRegisterVisible(false)
        return
      }
      const url = store.logModalRedirectUrl
      if (redirect || url) {
        link(redirect || url)
      }
      store.setLogRegisterVisible(false)
    }
  }

  const handleLogin = async values => {
    values.tokenTtl = tokenTtl || toKenTtlDefaultValue
    values.email = values?.email?.replace(/\s+/g, '')
    setLoading(true)
    const methodEnum = loginNameExp.test(values?.email || values?.mobile) ? UserValidateMethodEnum.loaginName : method
    switch (methodEnum) {
      case UserValidateMethodEnum.loaginName:
        const req = await getMemberLoginLoginName({
          loginName: values?.email,
          password: values?.password,
          tokenTtl: values.tokenTtl,
        })
        if (req?.isOk && req?.data) {
          handleLoginSuccess({ ...req }, { ...values })
        }
        setLoading(false)
        break
      case UserValidateMethodEnum.email:
        const isAccountType = IsAccountType(values.email)
        values.accountType = isAccountType

        if (!isAccountType) {
          setLoading(false)
          Message.warning(t`features/user/retrieve/index-0`)
          return
        }

        if (isAccountType === UserVerifyTypeEnum.email) {
          const res = await postMemberLoginEmail(values)
          handleLoginSuccess(res, values)
        }

        if (isAccountType === UserVerifyTypeEnum.uid) {
          const res = await postMemberLoginByUid({ ...values, uid: values.email })
          handleLoginSuccess(res, values)
        }
        setLoading(false)
        break
      case UserValidateMethodEnum.phone:
        values.mobileCountryCode = area.codeVal
        values.accountType = UserVerifyTypeEnum.phone
        values.mobile = values.mobile?.replace(/\s/g, '')

        const res = await postMemberLoginPhone(values)
        handleLoginSuccess(res, values)
        setLoading(false)
        break
      default:
        break
    }
  }

  const handlelRetrieve = () => {
    store.setIsResetPassword(true)
    store.setResetPasswordStatus({
      isCode: TabsResetPasswordStatusType.account,
      type: UserValidateMethodEnum.email,
    })
  }

  const geeTestOnSuccess = async () => {
    setLoading(false)
    await handleLogin(form.getFieldsValue())
  }

  const haveTheQrCodeLogin = async () => {
    const res = await postMemberLoginScan({ qrCode: qrCodeLoginInfo.current?.qrCode, tokenTtl })
    if (res.isOk && res.data) {
      if (res.data.token === null && !res.data.isWaiting) {
        setQrcodeButtonDisabled(true)
        setQrCodeShow(false)
        setQrCodeLoginText(t`features_user_login_index_2694`)
        return
      }

      if (res.data.token) {
        setToken(res.data)
        store.setUserInfo({ ...res.data?.userInfo, ...res.data?.usrMemberSettingsVO })
        store.setMemberBaseColor(
          res.data?.usrMemberSettingsVO?.marketSetting || UserUpsAndDownsColorEnum.greenUpRedDown
        )
        store.setLogin(true)
        redirect && link(redirect)
        store.setLogRegisterVisible(false)
      }
    }
  }

  const getQrCode = async () => {
    const browser = getBrowser()
    const system = getOperationSystem()
    const deviceName = `${browser} (${system})`
    const res = await postMemberLoginQrcode({ deviceName })
    if (res.isOk) {
      qrCodeLoginInfo.current = res.data
      setQrCodeInfo(res.data)
    }
  }

  const handleCountDown = async () => {
    await getQrCode()
    setQrCodeLoginText(t`user.login_05`)
    setQrCodeShow(true)
    setTargetDate(60 * 1000)
    setScanTime(5000)
  }

  useMount(handleCountDown)

  const geeTestOnError = () => setLoading(false)

  const onSubmit = async () => {
    setLoading(true)
    /** 极验验证 */
    geeTestInit(geeTestOnSuccess, geeTestOnError)
  }

  /** 忘记密码事件 */
  const onRetrieve = () => {
    if (popupLimit?.pwdLimitSwitch === GetUserLimitSwitchEnum.enable) {
      setResePasswordPopUpTips(true)
      return
    }
    handlelRetrieve()
  }

  /** 轮询接口 */
  useInterval(() => {
    if (qrcodeShow) {
      haveTheQrCodeLogin()
    }
  }, scanTime)

  useCountDown({
    leftTime: targetDate,
    onEnd: () => {
      setQrCodeLoginText(t`user.login_05`)
      setQrcodeButtonDisabled(false)
      setQrCodeShow(false)
      setTargetDate(0)
      setScanTime(undefined)
    },
  })

  useEffect(() => {
    const currentType = store.logOrRegisterType
    handleChoosMethod(currentType)
  }, [store.logOrRegisterType])

  const handleClearPassword = (key: string) => {
    form.setFieldValue(key, '')
    key === 'password' && setPassword('')
  }

  useEffect(() => {
    if (store?.selectTabData?.type === LoginTypeEnum.normal) {
      form.setFieldValue('password', '')
      setPassword('')
    }
  }, [store?.selectTabData?.type])

  return (
    <div className={`user-login user-form-style ${styles['user-login-wrap']}`}>
      <div className="user-login-wrap">
        <div className="form">
          {method !== logRegisterTypeEnum.qrcode && <LoginSwitch />}
          {method === logRegisterTypeEnum.qrcode && (
            <div className="qrcode">
              <div className="container">
                <div className="code">
                  <QRCodeCanvas style={{ width: '100%', height: '100%' }} value={qrcodeInfo?.loginInfo || ''} />

                  {!qrcodeShow && (
                    <>
                      <div className="mask"></div>
                      <div className="refresh">
                        <Button type="primary" onClick={handleCountDown} disabled={qrcodeButtonDisabled}>
                          {qrCodeLoginText}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="text">
                <label>{t`features_user_log_register_modal_component_user_login_index_qkxmskpwwi`}</label>
              </div>
            </div>
          )}
          <div className={`${store?.selectTabData?.type === LoginTypeEnum.normal ? 'form-block' : 'form-hidden'}`}>
            {method !== logRegisterTypeEnum.qrcode && (
              <Form form={form} layout="vertical" onSubmit={onSubmit} autoComplete="off" validateTrigger="onBlur">
                {method === UserValidateMethodEnum.email && (
                  <FormItem
                    label={`${t`user.validate_form_01`}/${t`features_agent_invitation_v2_rebate_records_v2_table_schema_6sqojf9pxw`}`}
                    field="email"
                    rules={[rule.account]}
                    requiredSymbol={false}
                  >
                    <Input
                      placeholder={`${t`features_user_login_index_2604`}/${t`features_agent_invitation_v2_rebate_records_v2_table_schema_6sqojf9pxw`}`}
                      suffix={<div></div>}
                    />
                  </FormItem>
                )}
                {method === UserValidateMethodEnum.phone && (
                  <FormItem
                    label={t`user.validate_form_03`}
                    field="mobile"
                    rules={[rule.phone]}
                    requiredSymbol={false}
                    className="custom-arco-form-item"
                    formatter={value => formatPhoneNumber(value, area?.codeVal)}
                    normalize={value => formatPhoneNumber(value, area?.codeVal, true)}
                  >
                    <Input
                      placeholder={t`user.field.reuse_11`}
                      addBefore={
                        <Select
                          onClick={() => setVisible(true)}
                          style={{ width: 100 }}
                          popupVisible={false}
                          arrowIcon={<Icon name="arrow_open" hasTheme />}
                          prefix={
                            <>
                              <Image preview={false} src={`${oss_area_code_image_domain_address}${area?.remark}.png`} />{' '}
                              +{area?.codeVal}{' '}
                            </>
                          }
                        />
                      }
                    />
                  </FormItem>
                )}
                <FormItem
                  label={t`user.validate_form_05`}
                  field="password"
                  formatter={FormValuesTrim}
                  rules={[rule.password]}
                  requiredSymbol={false}
                >
                  <Input
                    type={passwordShow ? 'text' : 'password'}
                    placeholder={t`user.validate_form_06`}
                    onChange={setPassword}
                    maxLength={32}
                    suffix={
                      <>
                        {password && (
                          <Icon name="del_input_box" hasTheme onClick={() => handleClearPassword('password')} />
                        )}

                        <Icon
                          className="theme-default-icon"
                          onClick={() => setPasswordShow(!passwordShow)}
                          name={passwordShow ? 'eyes_open' : 'eyes_close'}
                        />
                      </>
                    }
                  />
                </FormItem>

                <div className="retrieve">
                  <label
                    onClick={() => {
                      onRetrieve()
                    }}
                  >{t`user.login_03`}</label>
                </div>

                <FormItem style={{ marginBottom: 0 }}>
                  <Button loading={loading} type="primary" htmlType="submit">
                    {t`user.field.reuse_07`}
                  </Button>
                </FormItem>
              </Form>
            )}
          </div>
          <div
            className={`${
              store?.selectTabData?.type === LoginTypeEnum.verification && method !== logRegisterTypeEnum.qrcode
                ? 'form-block'
                : 'form-hidden'
            }`}
          >
            <LoginVerificationCode
              getAccountType={getAccountType}
              handleChoosMethod={handleChoosMethod}
              handleLoginSuccess={getMemberLoginGenerate}
              type={method}
              area={area}
              onRetrieve={onRetrieve}
              setVisible={setVisible}
            />
          </div>
          {store.hasMerchantTrialQualification && method !== logRegisterTypeEnum.qrcode && (
            <div className="register-or-retrieve">
              <div className="login">
                <label>{t`features_user_register_index_il9pgj1gld`}</label>
                <div
                  className="customize-link-style"
                  onClick={() => {
                    store.setTrialAccountInfo()
                    store.setLogRegisterVisible(false)
                  }}
                >
                  {t`features_user_register_index_wvdg2uy5cw`}
                </div>
              </div>
            </div>
          )}
          <SignInWith
            data={userLoginTypeData}
            dividerText={t`features_user_log_register_modal_component_flow_index_b0zd_ieaid`}
          />
        </div>
      </div>

      <UserPopUp
        title={<div style={{ textAlign: 'left' }}>{t`user.search_area_04`}</div>}
        className="user-popup"
        maskClosable={false}
        visible={visible}
        closeIcon={<Icon name="close" hasTheme />}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <UserSearchArea
          type="area"
          checkedValue={area?.codeVal}
          placeholderText={t`user.field.reuse_25`}
          selectArea={handleSelectArea}
        />
      </UserPopUp>

      <UserPopUp
        className="user-popup user-popup-tips"
        autoFocus={false}
        visible={resePasswordPopUpTips}
        closeIcon={<Icon name="close" />}
        okText={t`features_user_login_index_5101198`}
        cancelText={t`user.field.reuse_09`}
        onOk={handlelRetrieve}
        onCancel={() => setResePasswordPopUpTips(false)}
      >
        <UserPopupTipsContent
          headerIcon={<Icon name="msg" className="msg-icon-color" />}
          slotContent={<p>{t`features_user_log_register_modal_component_reset_password_index_gqkcmsrsv1`}.</p>}
        />
      </UserPopUp>
    </div>
  )
}

export default UserLogin
