import { useUserStore } from '@/store/user'
import Icon from '@/components/icon'
import { useUnmount } from 'ahooks'
import { useLayoutStore } from '@/store/layout'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { getGuidePageComponentInfoByKey } from '@/helper/layout'
import { LoginTypeEnum, UserValidateMethodEnum, logRegisterTypeEnum } from '@/constants/user'
import { Modal } from '@nbit/arco'
import {
  TabsTypeLogRegisterType,
  TabsResetPasswordStatusType,
} from '@/features/user/log-register-modal/component/log-type-tabs'
import { getOssManyBusinessImageAddress } from '@/constants/oss'
import GameLoginLeft from '@/features/recreation/theme/components/game-login-left'
import GameLoginRight from '@/features/recreation/theme/components/game-login-right'
import { useCommonStore } from '@/store/common'
import { useEffect } from 'react'
import { mobileVerify } from '@/helper/env'
import styles from './index.module.css'

export default function LogRegisterModal() {
  const {
    logOrRegister,
    isResetPassword,
    registerStatus,
    logRegisterVisible,
    resetPasswordStatus,
    setRegisterStatus,
    setIsResetPassword,
    setLogOrRegister,
    setLogOrRegisterType,
    setLogRegisterVisible,
    setResetPasswordStatus,
    setLogModalRedirectUrl,
    setThreePartyLoginList,
    setThreePartyLoginData,
    setSelectTab,
    selectTabData,
  } = useUserStore()
  const { localeInfo } = useCommonStore()

  const { headerData } = useLayoutStore()

  const guidePage = useGuidePageInfo()
  const { pageInfoTopBar = [] } = guidePage
  const homeIcon = getGuidePageComponentInfoByKey('homeIcon', pageInfoTopBar)

  const logOrRegisterMethod = () => {
    if (logOrRegister === TabsTypeLogRegisterType.log && localeInfo?.user?.login?.type) {
      return mobileVerify && localeInfo?.user?.login?.type === logRegisterTypeEnum.phone
        ? logRegisterTypeEnum.phone
        : logRegisterTypeEnum.email
    }
    if (logOrRegister === TabsTypeLogRegisterType.register && localeInfo?.user?.register?.type) {
      return mobileVerify && localeInfo?.user?.register?.type === logRegisterTypeEnum.phone
        ? logRegisterTypeEnum.phone
        : logRegisterTypeEnum.email
    }
    return logRegisterTypeEnum.email
  }

  /** 弹框展示就要把数据初始化 */
  const initializationData = () => {
    const islogOrRegister =
      logOrRegister === TabsTypeLogRegisterType.log ? localeInfo?.user?.login?.type : localeInfo?.user?.register?.type
    setRegisterStatus({
      isCode: false,
      type: UserValidateMethodEnum.email,
    })
    setResetPasswordStatus({
      isCode: TabsResetPasswordStatusType.account,
      type: UserValidateMethodEnum.email,
    })
    setIsResetPassword(false)
    setLogModalRedirectUrl('')
    setThreePartyLoginList({})
    setThreePartyLoginData({})
    setLogOrRegisterType(logOrRegisterMethod())
  }

  /** 注册登录切换，并把数据初始化 */
  const onTabsChange = (key: TabsTypeLogRegisterType) => {
    setLogOrRegister(key)
    initializationData()
    setSelectTab({ ...selectTabData, type: LoginTypeEnum.normal })
  }

  // 弹框卸载要初始化
  useUnmount(() => {
    initializationData()
  })

  useEffect(() => {
    if (logRegisterVisible) {
      initializationData()
    }
  }, [logRegisterVisible, logOrRegister])

  return (
    <Modal
      unmountOnExit
      footer={null}
      closable={false}
      maskClosable={false}
      visible={logRegisterVisible}
      className={styles['log-register-modal-wrap']}
    >
      <Icon name="close" className="right-icon" onClick={() => setLogRegisterVisible(false)} />
      <GameLoginLeft imageVal={`${getOssManyBusinessImageAddress()}left-back-image.png`} />
      <GameLoginRight
        onTabsChange={onTabsChange}
        logOrRegister={logOrRegister}
        isResetPassword={isResetPassword}
        registerStatus={registerStatus}
        resetPasswordStatus={resetPasswordStatus}
      />
    </Modal>
  )
}
