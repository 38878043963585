import { useState, useEffect, useMemo } from 'react'
import { useMount } from 'react-use'
import { Button, Form, Select, Image, Input, Checkbox, Message } from '@nbit/arco'
import { useUpdateEffect } from 'ahooks'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import UserSearchArea from '@/features/user/common/search-area'
import {
  UserValidateMethodEnum,
  UserVerifyTypeEnum,
  UserRegisterTypeEnum,
  UserAgreementEnum,
  ChinaAreaCodeEnum,
  logRegisterTypeEnum,
} from '@/constants/user'
import { useLayoutStore } from '@/store/layout'
import Icon from '@/components/icon'
import { useUserStore } from '@/store/user'
import UserPopUp from '@/features/user/components/popup'
import { MemberMemberAreaType } from '@/typings/user'
import { useGeeTestBind } from '@/features/user/common/geetest'
import { usePageContext } from '@/hooks/use-page-context'
import { FormValuesTrim } from '@/features/user/utils/common'
import SignInWith from '@/features/user/login/component/sign-in-with'
import UserPasswordValidateTips from '@/features/user/common/password-validate'
import { oss_area_code_image_domain_address, oss_svg_image_domain_address } from '@/constants/oss'
import { RegisterFlowRules, formatPhoneNumber, loginNameValidate } from '@/features/user/utils/validate'
import {
  postMemberRegisterValidEmail,
  postMemberRegisterValidPhone,
  getMemberAreaIp,
  getRegisterVerifyStatus,
  postMemberRegisterEmail,
  postMemberRegisterPhone,
  getMemberBaseUpdateLoginName,
  postMemberBaseUpdateLoginName,
} from '@/apis/user'
import LazyImage, { Type } from '@/components/lazy-image'
import UserPopUpSuccessContent from '@/features/user/components/popup/content/success'
import { setToken } from '@/helper/auth'
import { useCommonStore } from '@/store/common'
import { mobileVerify } from '@/helper/env'
import styles from './index.module.css'

const FormItem = Form.Item

function UserRegisterFlow() {
  const { localeInfo } = useCommonStore()
  const [method, setMethod] = useState<string>(UserValidateMethodEnum.email)
  const [selectArea, setSelectArea] = useState<boolean>(false)
  const [passwordValue, setPasswordValue] = useState<string>('')
  const [passwordValidate, setPasswordValidate] = useState<boolean>(false)
  const [invitationCodeShow, setInvitationCodeShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [checkBoxStatus, setCheckBoxStatus] = useState<boolean>(false)
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false)
  const [area, setArea] = useState({
    codeVal: ChinaAreaCodeEnum.code,
    codeKey: t`features_user_personal_center_account_security_phone_index_2432`,
    remark: ChinaAreaCodeEnum.remark,
  })
  const [passwordShow, setPasswordShow] = useState({
    newPasswordShow: true,
    confirmPasswordShow: true,
  })
  const [form] = Form.useForm()
  const loginPassword = Form.useWatch('loginPassword', form)
  const confirmPassword = Form.useWatch('confirmPassword', form)
  const loginName = Form.useWatch('loginName', form)
  const email = Form.useWatch('email', form)
  const geeTestInit = useGeeTestBind()

  // const isAccount = email || mobile

  const rules = RegisterFlowRules(loginPassword)

  useUpdateEffect(() => {
    loginPassword && confirmPassword && form.validate(['loginPassword', 'confirmPassword'])
  }, [loginPassword, confirmPassword])
  const pageContext = usePageContext()
  const store = useUserStore()
  const layoutStore = useLayoutStore()
  const { businessName } = layoutStore.footerData || {}
  const info = store.userTransitionDatas
  const { invitationCode, regCountry = null } = pageContext.urlParsed.search

  const registerTypeData = [
    {
      isHide: mobileVerify,
      key: '1',
      name: t`user.safety_items_04`,
      type: logRegisterTypeEnum.email,
      icon: 'icon_mail',
    },
    {
      isHide: mobileVerify,
      key: '2',
      name: t`user.safety_items_02`,
      type: logRegisterTypeEnum.phone,
      icon: 'icon_phone',
    },
  ]

  const getThirdPartyAccountDisplayType = useMemo(() => {
    if (info.registerType === UserRegisterTypeEnum.default) {
      return [
        { title: t`user.safety_items_04`, id: 'email' },
        // { title: t`user.validate_form_header_02`, id: 'phone' },
      ]
    }

    setMethod(
      info.thirdPartyAccountType === UserVerifyTypeEnum.email
        ? UserValidateMethodEnum.email
        : UserValidateMethodEnum.phone
    )

    return info.thirdPartyAccountType === UserVerifyTypeEnum.email
      ? [{ title: t`user.safety_items_04`, id: 'email' }]
      : [{ title: t`user.validate_form_header_02`, id: 'phone' }]
  }, [info.registerType, info.thirdPartyAccountType])

  useEffect(() => {
    if (info.thirdPartyAccount) {
      info.thirdPartyAccountType === UserVerifyTypeEnum.email
        ? form.setFieldValue('email', info.thirdPartyAccount)
        : form.setFieldValue('mobileNumber', info.thirdPartyAccount)

      setMethod(
        info.thirdPartyAccountType === UserVerifyTypeEnum.email
          ? UserValidateMethodEnum.email
          : UserValidateMethodEnum.phone
      )
    }
  }, [])

  // useEffect(() => {
  //   setArea({
  //     codeVal: info.codeVal || ChinaAreaCodeEnum.code,
  //     codeKey: info.codeKey || t`features_user_personal_center_account_security_phone_index_2432`,
  //     remark: info.remark || ChinaAreaCodeEnum.remark,
  //   })
  // }, [])

  const getAreaIp = async () => {
    const res = await getMemberAreaIp({})
    if (res.isOk) {
      const { enCode, fullName, shortName } = res.data
      setArea({
        codeVal: enCode || ChinaAreaCodeEnum.code,
        codeKey: fullName || t`features_user_personal_center_account_security_phone_index_2432`,
        remark: shortName || ChinaAreaCodeEnum.remark,
      })
    }
  }

  useMount(getAreaIp)

  // const handleValidateChange = () => {
  //   form
  //     .validate()
  //     .then(() => setDisabled(false))
  //     .catch(() => setDisabled(true))
  // }

  const handleClearPassword = (key: string) => {
    form.setFieldValue(key, '')
    key === 'loginPassword' && setPasswordValue('')
  }

  const handleChoosMethod = (type: string) => {
    setMethod(type)
    setPasswordValue('')
    setCheckBoxStatus(false)
    form.clearFields()
    invitationCode && form.setFieldValue('invite', invitationCode)
  }

  const handleSelectArea = (v: MemberMemberAreaType) => {
    // if (v.enableInd === UserEnabledStateTypeEnum.enable) setIsEnble(true)
    setArea(v as unknown as any)
    setSelectArea(false)
  }

  const handleRegister = async values => {
    values.regCountry = regCountry
    values.mobileCountryCode = area.codeVal
    values.mobileNumber = values.mobileNumber?.replace(/\s/g, '')
    setLoading(true)
    store.setUserTransitionDatas(values)
    const status = await getRegisterVerifyStatus({})
    const emailVerify = status?.data?.emailVerify
    const mobileVerify = status?.data?.mobileVerify
    switch (method) {
      case UserValidateMethodEnum.email:
        const emailRes = await postMemberRegisterValidEmail(values)

        if (emailRes.isOk) {
          if (emailVerify) {
            // 极验验证成功，进入邮箱验证
            emailRes.data?.isSuccess &&
              store?.setRegisterStatus({
                isCode: true,
                type: method,
                loginName: form.getFieldValue('loginName'),
              })
            return
          }
          const emailRegisterRes = await postMemberRegisterEmail(values)
          handleRegisterSuccess(emailRegisterRes)
        }
        setLoading(false)
        break
      case UserValidateMethodEnum.phone:
        values.mobileCountryCode = area.codeVal

        const phoneRes = await postMemberRegisterValidPhone(values)
        if (phoneRes.isOk) {
          if (mobileVerify) {
            // 极验验证成功，进入邮箱验证
            phoneRes.data?.isSuccess &&
              store?.setRegisterStatus({
                isCode: true,
                type: method,
                loginName: form.getFieldValue('loginName'),
              })
            return
          }
          const phoneRegisterRes = await postMemberRegisterPhone(values)
          handleRegisterSuccess(phoneRegisterRes)
        }
        setLoading(false)
        break
      default:
        break
    }
  }

  const handleRegisterSuccess = async res => {
    if (res.isOk && res.data?.isSuccess) {
      setToken(res.data)
      store.setUserInfo({ ...res.data?.userInfo, ...res.data?.usrMemberSettingsVO })
      store.setLogin(true)
      store.removeUserTransitionDatas()
      setRegisterSuccess(true)
      if (loginName) {
        const req = await postMemberBaseUpdateLoginName({
          loginName,
        })
        if (req.isOk && req?.data) {
          store?.setIsShowLoginName(true)
        }
      }
    }
  }

  const geeTestOnSuccess = async () => {
    setLoading(false)
    handleRegister(form.getFieldsValue())
  }

  const geeTestOnError = () => setLoading(false)

  const onSubmit = async values => {
    // if (method === UserValidateMethodEnum.phone && !isEnble) {
    //   Message.warning(t`features_user_register_flow_index_2693`)
    //   return
    // }

    if (!passwordValidate) {
      Message.warning(t`features_user_register_flow_index_2448`)
      return
    }

    if (!values.serviceAgreement) {
      Message.warning(t`features_user_register_flow_index_2450`)
      return
    }

    setLoading(true)

    if (loginName) {
      const res = await getMemberBaseUpdateLoginName({
        loginName,
      })
      if (res?.isOk) {
        if (typeof res.data === 'boolean' && !res.data) {
          form.setFields({
            loginName: {
              error: {
                message: t`features_user_log_register_modal_component_flow_index_bc8jjkqxmh`,
              },
            },
          })
          setLoading(false)
          return
        }
      }
    }

    /** 极验验证 */
    geeTestInit(geeTestOnSuccess, geeTestOnError)
  }

  useEffect(() => {
    invitationCode && form.setFieldValue('invite', invitationCode)
  }, [])

  useEffect(() => {
    const currentType = store.logOrRegisterType
    handleChoosMethod(currentType)
  }, [store.logOrRegisterType])

  return (
    <div className={`user-register-flow user-form-style ${styles['register-modal-wrap']}`}>
      <div className="user-register-flow-wrap">
        {/* <UserChooseVerificationMethod
          tabList={getThirdPartyAccountDisplayType}
          method={method}
          choosMethod={handleChoosMethod}
        /> */}

        <Form
          form={form}
          layout="vertical"
          onSubmit={onSubmit}
          autoComplete="off"
          initialValues={{ serviceAgreement: false }}
          validateTrigger="onBlur"
          // onChange={handleValidateChange}
        >
          {method === UserValidateMethodEnum.email && (
            <FormItem label={t`user.safety_items_04`} field="email" requiredSymbol={false} rules={[rules.email]}>
              <Input
                suffix={
                  <div>{email && <Icon name="del_input_box" onClick={() => form.setFieldValue('email', '')} />}</div>
                }
                placeholder={t`user.validate_form_02`}
                disabled={info.thirdPartyAccount}
              />
            </FormItem>
          )}
          {method === UserValidateMethodEnum.phone && (
            <FormItem
              className="custom-arco-form-item"
              label={t`user.validate_form_03`}
              field="mobileNumber"
              requiredSymbol={false}
              rules={[rules.phone]}
              formatter={value => formatPhoneNumber(value, area?.codeVal)}
              normalize={value => formatPhoneNumber(value, area?.codeVal, true)}
            >
              <Input
                placeholder={t`user.field.reuse_11`}
                addBefore={
                  <Select
                    onClick={() => setSelectArea(true)}
                    style={{ width: 100 }}
                    popupVisible={false}
                    arrowIcon={<Icon name="arrow_open" hasTheme />}
                    prefix={
                      <>
                        <Image preview={false} src={`${oss_area_code_image_domain_address}${area?.remark}.png`} /> +
                        {area?.codeVal}{' '}
                      </>
                    }
                  />
                }
              />
            </FormItem>
          )}
          <FormItem
            label={t`user.validate_form_05`}
            field="loginPassword"
            requiredSymbol={false}
            formatter={FormValuesTrim}
            rules={[rules.password]}
          >
            <Input
              type={passwordShow.newPasswordShow ? 'password' : 'text'}
              maxLength={32}
              onChange={setPasswordValue}
              placeholder={t`user.validate_form_06`}
              suffix={
                <>
                  {loginPassword && <Icon name="del_input_box" onClick={() => handleClearPassword('loginPassword')} />}

                  <Icon
                    name={passwordShow.newPasswordShow ? 'eyes_open' : 'eyes_close'}
                    className="theme-default-icon"
                    onClick={() =>
                      setPasswordShow({
                        ...passwordShow,
                        newPasswordShow: !passwordShow.newPasswordShow,
                      })
                    }
                  />
                </>
              }
            />
          </FormItem>
          <UserPasswordValidateTips password={FormValuesTrim(passwordValue) as string} validate={setPasswordValidate} />
          <FormItem
            label={t`user.field.reuse_12`}
            field="confirmPassword"
            requiredSymbol={false}
            formatter={FormValuesTrim}
            rules={[rules.confirmPassword]}
          >
            <Input
              type={passwordShow.confirmPasswordShow ? 'password' : 'text'}
              maxLength={32}
              placeholder={t`features_user_log_register_modal_component_reset_password_index_se9g4zkexl`}
              suffix={
                <>
                  {confirmPassword && (
                    <Icon name="del_input_box" onClick={() => handleClearPassword('confirmPassword')} />
                  )}

                  <Icon
                    name={passwordShow.confirmPasswordShow ? 'eyes_open' : 'eyes_close'}
                    className="theme-default-icon"
                    onClick={() =>
                      setPasswordShow({
                        ...passwordShow,
                        confirmPasswordShow: !passwordShow.confirmPasswordShow,
                      })
                    }
                  />
                </>
              }
            />
          </FormItem>
          <FormItem
            label={
              <div className={styles['scoped-label']}>
                <div className="left-text"> {t`features_user_log_register_modal_component_flow_index_neanzwzbag`}</div>
                <div className="rigth-text"> {t`features_user_log_register_modal_component_flow_index_wyjaglxjcn`}</div>
              </div>
            }
            field="loginName"
            requiredSymbol={false}
            formatter={FormValuesTrim}
            rules={[loginNameValidate(false)]}
          >
            <Input
              suffix={
                <div>
                  {loginName && <Icon name="del_input_box" onClick={() => form.setFieldValue('loginName', '')} />}
                </div>
              }
              type={'text'}
              maxLength={20}
              placeholder={t`features_user_log_register_modal_component_flow_index_ifk8k7gwm7`}
            />
          </FormItem>
          <FormItem
            field="serviceAgreement"
            requiredSymbol={false}
            rules={[rules.serviceAgreement]}
            className="flow-form-item"
          >
            <Checkbox onChange={setCheckBoxStatus}>
              {() => {
                return (
                  <div className="service-agreement">
                    <Icon
                      className={checkBoxStatus ? 'text-auxiliary_color_01' : 'text-icon_color_02'}
                      name={
                        checkBoxStatus
                          ? localeInfo?.agreement?.selectIcon || 'icon_agree_yes'
                          : localeInfo?.agreement?.uncheckedIcon || 'icon_agree_yes'
                      }
                    />
                    <div className="text">
                      <label>
                        {t({
                          id: 'user.validate_form_09',
                          values: { 0: layoutStore.headerData?.businessName },
                        })}
                      </label>
                      <div
                        className="customize-link-style"
                        onClick={() => {
                          store.setLogRegisterVisible(false)
                          link(layoutStore.columnsDataByCd?.[UserAgreementEnum.termsService]?.webUrl)
                        }}
                      >
                        {'《'}
                        {t`user.validate_form_10`}
                        {'》'}
                      </div>
                    </div>
                  </div>
                )
              }}
            </Checkbox>
          </FormItem>
          <div className="invitation-code" onClick={() => setInvitationCodeShow(!invitationCodeShow)}>
            <label>{t`user.validate_form_07`}</label>
            <Icon name={invitationCodeShow ? 'arrow_close' : 'arrow_open'} hasTheme fontSize={8} />
          </div>
          {invitationCodeShow && (
            <FormItem field="invite" formatter={FormValuesTrim} className="register-modal-invitation-code">
              <Input placeholder={t`user.validate_form_08`} disabled={!!invitationCode} />
            </FormItem>
          )}
          <FormItem>
            <Button loading={loading} type="primary" htmlType="submit">
              {t`user.validate_form_11`}
            </Button>
          </FormItem>
          {store.hasMerchantTrialQualification && (
            <div className="flow-footer">
              <div className="login">
                <label>{t`features_user_register_index_il9pgj1gld`}</label>
                <div
                  className="customize-link-style"
                  onClick={() => {
                    store.setTrialAccountInfo()
                    store.setLogRegisterVisible(false)
                  }}
                >
                  {t`features_user_register_index_wvdg2uy5cw`}
                </div>
              </div>
            </div>
          )}
        </Form>

        <SignInWith
          data={registerTypeData}
          dividerText={t`features_user_log_register_modal_component_flow_index_b0zd_ieaid`}
        />

        <UserPopUp
          title={<div style={{ textAlign: 'left' }}>{t`user.search_area_04`}</div>}
          className="user-popup"
          maskClosable={false}
          visible={selectArea}
          closeIcon={<Icon name="close" hasTheme />}
          onCancel={() => setSelectArea(false)}
          footer={null}
        >
          <UserSearchArea
            type="area"
            checkedValue={area?.codeVal}
            placeholderText={t`user.field.reuse_25`}
            selectArea={handleSelectArea}
          />
        </UserPopUp>

        <UserPopUp
          className="user-popup user-popup-success"
          visible={registerSuccess}
          closeIcon={<Icon name="close" hasTheme />}
          onCancel={async () => {
            setRegisterSuccess(false)
          }}
          footer={
            <Button
              type="primary"
              onClick={async () => {
                setRegisterSuccess(false)
              }}
            >
              {t({
                id: 'features_user_register_verification_index_5101343',
                values: { 0: businessName },
              })}
            </Button>
          }
        >
          <UserPopUpSuccessContent
            slotContent={<h3>{t`user.register_08`}</h3>}
            icon={
              <div className="register-icon">
                <LazyImage
                  hasTheme
                  imageType={Type.svg}
                  className="nb-icon-png"
                  src={`${oss_svg_image_domain_address}sign_complete`}
                />
              </div>
            }
          />
        </UserPopUp>
      </div>
    </div>
  )
}

export default UserRegisterFlow
