import { useState, useEffect, useMemo, useRef } from 'react'
import { Button, Form, Input, Message } from '@nbit/arco'
import { useRequest } from 'ahooks'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import UserPopUp from '@/features/user/components/popup'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import UserCountDown from '@/features/user/components/count-down'
import FullScreenSpin from '@/features/user/components/full-screen-loading'
import {
  SignInWithEnum,
  UserVerifyTypeEnum,
  LoginTypeStatusEnum,
  UserValidateMethodEnum,
  ThirdPartyCheckoutType,
  UserEnabledStateTypeEnum,
  UserUpsAndDownsColorEnum,
  UserSendValidateCodeBusinessTypeEnum,
  GetUserTypeEnum,
} from '@/constants/user'
import {
  postRegisterGoogleRequest,
  postRegisterAppleRequest,
  postMemberSafeVerifyEmailSend,
  postMemberSafeVerifyPhoneSend,
  postMemberSafeVerifyEmailCheck,
  postMemberSafeVerifyPhoneCheck,
  getMemberSafeVerifyGoogleCheck,
  postMemberLoginGenerateUserInfomation,
  postMemberQueryWorkOrderStatus,
} from '@/apis/user'
import { TabsTypeLogRegisterType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { UserInformationDesensitization } from '@/features/user/utils/common'
import { SafetyVerificationRules } from '@/features/user/utils/validate'
import { useUserStore } from '@/store/user'
import { setToken } from '@/helper/auth'
import { usePageContext } from '@/hooks/use-page-context'
import { useCustomerServiceLink } from '@/hooks/features/customer-service/index'
import { getSafetyRoutePath } from '@/helper/route/user'
import GameVerifyMode from '@/features/recreation/component/game-verify-mode'
import Icon from '@/components/icon'
import styles from './index.module.css'

const FormItem = Form.Item
const expirationCode = [10000039]

function UserSafetyVerification() {
  const [verifyMethod, setVerifyMethod] = useState<string>(UserValidateMethodEnum.email)
  const [workOrderPopUp, setWorkOrderPopUp] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [spinShow, setSpinShow] = useState<boolean>(false)
  const isEmailSend = useRef<boolean>(false)
  const isPhoneSend = useRef<boolean>(false)
  const customerServiceLinks = useCustomerServiceLink()
  // const [disabled, setDisabled] = useState<boolean>(true)
  const store = useUserStore()
  const [form] = Form.useForm()
  const pageContext = usePageContext()
  const { merchantSettings } = useUserStore()
  // const verifyCode = Form.useWatch('verifyCode', form)

  const { redirect, recreation } = pageContext.urlParsed.search
  const info = store.userTransitionDatas
  const userInfo = info.userInfo

  const rules = SafetyVerificationRules(isEmailSend, isPhoneSend)

  const verifyInputShow = useMemo(() => {
    return merchantSettings?.isEmailEnable && userInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable
      ? UserValidateMethodEnum.email
      : merchantSettings?.isMobileEnable && userInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable
      ? UserValidateMethodEnum.phone
      : UserValidateMethodEnum.validator
  }, [])

  useEffect(() => {
    setVerifyMethod(verifyInputShow)
  }, [])

  // const handleValidateChange = () => {
  //   form
  //     .validate()
  //     .then(() => setDisabled(false))
  //     .catch(() => setDisabled(true))
  // }

  const handleSafetyItems = () => {
    link(getSafetyRoutePath(true, GetUserTypeEnum.login))
    store.setLogRegisterVisible(false)
  }

  const handleToSecurityItem = async () => {
    const options = {
      type: info.accountType,
      account:
        info.accountType === UserVerifyTypeEnum.phone
          ? info.mobile
          : info.accountType === UserVerifyTypeEnum.email
          ? info.email
          : userInfo?.uid,
      mobileCountryCode: info.accountType === UserVerifyTypeEnum.phone ? info.mobileCountryCode : undefined,
    }
    const res = await postMemberQueryWorkOrderStatus(options)
    if (res?.data && res?.data?.isBeginManualVerifyProcess) {
      setWorkOrderPopUp(true)
      return
    }
    store.setLogRegisterVisible(false)
    handleSafetyItems()
  }

  const switchVerifyMethod = (type: string) => {
    setVerifyMethod(type)
  }

  const handleSendEmailValidateCode = async () => {
    const res = await postMemberSafeVerifyEmailSend({
      type: UserSendValidateCodeBusinessTypeEnum.login,
      email: userInfo?.email as string,
    })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isEmailSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleSendPhoneValidateCode = async () => {
    const res = await postMemberSafeVerifyPhoneSend({
      type: UserSendValidateCodeBusinessTypeEnum.login,
      mobileCountryCode: userInfo?.mobileCountryCd as string,
      mobile: userInfo?.mobileNumber as string,
    })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isPhoneSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleLoginSuccess = async data => {
    if (data.token) {
      setToken(data)
      store.setMultipleLoginTime(data?.eventTime)
      await store.setUserInfo({ ...data?.userInfo, ...data?.usrMemberSettingsVO })
      await store.setMemberBaseColor(
        data?.usrMemberSettingsVO?.marketSetting || UserUpsAndDownsColorEnum.greenUpRedDown
      )
      store.setLogin(true)
      /**  请求商户信息 */
      await store.removeUserTransitionDatas()
      Message.success(t`features/user/safety-verification/index-0`)
      store?.setIsShowLoginName(store?.isShowLoginName ? store?.isShowLoginName : Boolean(data?.userInfo?.loginName))
      if (recreation) {
        store.setLogRegisterVisible(false)
        return
      }
      const url = store.logModalRedirectUrl
      if (redirect || url) {
        link(redirect || url)
        store.setLogRegisterVisible(false)
        return
      }
      link('/')
      store.setLogRegisterVisible(false)
    }
  }

  const getRouterParams = () => {
    const routeParams = pageContext?.urlParsed
    return routeParams?.search?.bindType
  }

  const resetLogin = () => {
    store.setRegisterStatus({
      isCode: false,
      type: UserValidateMethodEnum.email,
    })
    store.setIsResetPassword(false)
    store.setLogOrRegister(TabsTypeLogRegisterType.log)
  }

  const handleLogin = async (code: number) => {
    // 登录过期码 10000039
    if (expirationCode.includes(code)) {
      // link('/login')
      resetLogin()
      return
    }

    setLoading(true)

    const options = {
      account:
        info.accountType === UserVerifyTypeEnum.phone
          ? info.mobile
          : info.accountType === UserVerifyTypeEnum.email
          ? info.email
          : userInfo?.uid,
      accountType: info.accountType,
      mobileCountryCode: info.mobileCountryCode,
    }

    setSpinShow(true)
    const bindType = getRouterParams()
    /** 如果是绑定，要走绑定注册接口 */
    if (bindType) {
      const params = {
        uid: userInfo.uid,
        idToken: store.thirdPartyToken,
        status: LoginTypeStatusEnum.needBind,
      } as any
      bindType === SignInWithEnum.apple && (params.type = ThirdPartyCheckoutType.apple)
      const requestParams = bindType === SignInWithEnum.google ? postRegisterGoogleRequest : postRegisterAppleRequest
      const { data, isOk } = await requestParams(params)
      if (isOk && data) {
        handleLoginSuccess(data)
        return
      }
      return
    }
    const res = await postMemberLoginGenerateUserInfomation(options)
    if (res.isOk) {
      handleLoginSuccess(res.data)
      return
    }
    // 登录过期码 10000039
    if (expirationCode.includes(res.code as number)) {
      // link('/login')
      // 回到登录
      resetLogin()
      return
    }

    setSpinShow(false)
    setLoading(false)
  }

  const handleValidateCode = async values => {
    switch (verifyMethod) {
      case UserValidateMethodEnum.email:
        const emailRes = await postMemberSafeVerifyEmailCheck({ ...values, email: userInfo?.email })
        if (emailRes.isOk) {
          emailRes.data?.isSuccess && handleLogin(emailRes.code as number)
          break
        }

        setLoading(false)
        break
      case UserValidateMethodEnum.validator:
        values.uid = userInfo?.uid

        const googleRes = await getMemberSafeVerifyGoogleCheck(values)
        if (googleRes.isOk) {
          googleRes.data?.isSuccess && handleLogin(googleRes.code as number)
          break
        }

        setLoading(false)
        break
      case UserValidateMethodEnum.phone:
        values.mobileCountryCode = userInfo?.mobileCountryCd
        values.mobile = userInfo?.mobileNumber

        const phoneRes = await postMemberSafeVerifyPhoneCheck(values)
        if (phoneRes.isOk) {
          phoneRes.data?.isSuccess && handleLogin(phoneRes.code as number)
          break
        }

        setLoading(false)
        break
      default:
        break
    }
  }

  const { run } = useRequest(handleValidateCode, {
    throttleWait: 2000,
    manual: true,
  })

  const onSubmit = async values => {
    setLoading(true)
    values.type = UserSendValidateCodeBusinessTypeEnum.login

    run(values)
  }

  // 返回上一步
  const backStatus = () => {
    const type = info.accountType === UserValidateMethodEnum.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone
    store.setRegisterStatus({
      isCode: false,
      type,
    })
  }

  const handlePaste = () => {
    navigator.clipboard.readText().then(text => {
      text && form.setFieldValue('verifyCode', text)
    })
  }

  return (
    <div className={`user-safety-verification user-form-style ${styles.scoped}`}>
      <div className="user-safety-verification-wrap">
        <div className="safety-verification-title-header">
          <Icon name="nav_icon_back" className="retrive-icon" onClick={() => backStatus()} />
          <p>{t`user.pageContent.title_06`}</p>
        </div>
        <Form
          form={form}
          layout="vertical"
          onSubmit={onSubmit}
          autoComplete="off"
          validateTrigger="onBlur"
          // onChange={handleValidateChange}
        >
          {merchantSettings?.isEmailEnable &&
            userInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable &&
            verifyMethod === UserValidateMethodEnum.email && (
              <FormItem
                label={t`user.field.reuse_03`}
                field="verifyCode"
                requiredSymbol={false}
                rules={[rules.emailCode]}
                extra={t({
                  id: 'user.universal_security_verification_01',
                  values: { 0: UserInformationDesensitization(userInfo?.email) },
                })}
              >
                <Input
                  type="number"
                  maxLength={6}
                  placeholder={t`features_user_log_register_modal_component_verification_index_pvlmpiqrvh`}
                  suffix={<UserCountDown onSend={handleSendEmailValidateCode} />}
                />
              </FormItem>
            )}

          {merchantSettings?.isMobileEnable &&
            userInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable &&
            verifyMethod === UserValidateMethodEnum.phone && (
              <FormItem
                label={t`user.field.reuse_04`}
                field="verifyCode"
                requiredSymbol={false}
                rules={[rules.phoneCode]}
                extra={t({
                  id: 'user.universal_security_verification_03',
                  values: {
                    0: `+${userInfo?.mobileCountryCd} ${UserInformationDesensitization(userInfo?.mobileNumber)}`,
                  },
                })}
              >
                <Input
                  type="number"
                  maxLength={6}
                  placeholder={t`features_user_log_register_modal_component_verification_index_pvlmpiqrvh`}
                  suffix={<UserCountDown onSend={handleSendPhoneValidateCode} />}
                />
              </FormItem>
            )}

          {merchantSettings?.isGoogleEnable &&
            userInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable &&
            verifyMethod === UserValidateMethodEnum.validator && (
              <FormItem
                label={t`user.field.reuse_05`}
                field="verifyCode"
                requiredSymbol={false}
                rules={[rules.googleKey]}
                extra={t`user.safety_verification_06`}
              >
                <Input
                  type="number"
                  maxLength={6}
                  placeholder={t`user.safety_verification_05`}
                  suffix={
                    <span
                      className="affix"
                      onClick={() => handlePaste()}
                    >{t`features_user_log_register_modal_component_safety_verification_index_dbyangnlzf`}</span>
                  }
                />
              </FormItem>
            )}

          <GameVerifyMode
            className="verify-mode"
            merchantSettings={merchantSettings}
            userInfo={userInfo}
            verifyMethod={verifyMethod}
            switchVerifyMethod={switchVerifyMethod}
            handleToSecurityItem={handleToSecurityItem}
          />

          <FormItem style={{ marginBottom: 0 }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={disabled || !verifyCode}
            >
              {t`user.field.reuse_02`}
            </Button>
          </FormItem>
        </Form>
      </div>

      {workOrderPopUp && (
        <UserPopUp
          className="user-popup user-popup-tips"
          visible={workOrderPopUp}
          closeIcon={<Icon name="close" hasTheme />}
          okText={t`user.safety_verification_14`}
          cancelText={t`user.field.reuse_48`}
          onOk={() => {
            link(customerServiceLinks.toString())
            setWorkOrderPopUp(false)
          }}
          onCancel={() => setWorkOrderPopUp(false)}
        >
          <UserPopupTipsContent slotContent={<p>{t`user.safety_verification_13`}</p>} />
        </UserPopUp>
      )}

      <FullScreenSpin isShow={spinShow} />
    </div>
  )
}

export default UserSafetyVerification
