/** 登录切换组件 */
import { LoginTypeEnum } from '@/constants/user'
import { useUserStore } from '@/store/user'
import { useMount } from 'react-use'
import { getMemberSafeSafetyInformation } from '@/apis/user'
import { t } from '@lingui/macro'
import style from './index.module.css'

function LoginSwitch() {
  const { setSelectTab, selectTabData } = useUserStore()

  const tabList = [
    {
      name: t`features_user_log_register_modal_component_user_login_login_switch_index_mlifxppifk`,
      value: LoginTypeEnum.normal,
    },
    {
      name: t`features_user_log_register_modal_component_user_login_login_switch_index_l2bgba5p_t`,
      value: LoginTypeEnum.verification,
    },
  ]

  /** 查询验证信息 */
  const queryMemberSafeSafetyInformation = async () => {
    const res = await getMemberSafeSafetyInformation({})
    if (res?.isOk && res?.data) {
      setSelectTab({
        ...selectTabData,
        codeLogin: res?.data?.codeLogin,
        type: LoginTypeEnum.normal,
      })
    }
  }

  /** tab切换事件 */
  const onTab = item => {
    setSelectTab({ ...selectTabData, type: item?.value })
  }

  useMount(queryMemberSafeSafetyInformation)
  return (
    <div>
      {selectTabData?.codeLogin === LoginTypeEnum.verification && (
        <div className={style['scope-login-switch']}>
          <div className="login-switch-flex">
            {tabList.map(item => {
              return (
                <div
                  className={`text ${selectTabData?.type === item?.value ? 'block' : ''}`}
                  onClick={() => onTab(item)}
                  key={item?.value}
                >
                  {item?.name || ''}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default LoginSwitch
